//import { FieldValue } from "firebase-admin/firestore";
import { AdmUserData } from "../interfaces/Adms";
import { Contact } from "../interfaces/Contact";
import { Aluno, StudentData, UserData } from "../interfaces/Students";
import { MaterialsData, PackageData, ScheduleData, TurmaOptionData } from "../interfaces/Turmas";
import { firestore, firebase } from "./firebaseConfig";
import { collection, query, where, getDocs } from 'firebase/firestore';

const addContactFirestore = async (contact: Contact) => {
  try {
    const { cpf, id, ...contactData } = contact;

    // Remove pontos do CPF
    const sanitizedCpf = cpf.replace(/[.-]/g, "");

    // Cria um novo documento usando o ID fornecido
    const contactRef = firestore.collection("matriculas-limoeiro-LT1").doc(id);

    // Adiciona os dados do contato ao documento
    await contactRef.set({
      cpf: sanitizedCpf,
      id,
      ...contactData,
    });

    //console.log("Contato adicionado ao Firestore com sucesso!");
  } catch (error) {
    console.error("Erro ao adicionar contato ao Firestore:", error);
    throw error;
  }
};

const getContactsFirestore = async (): Promise<Contact[]> => {
  try {
    const snapshot = await firestore.collection("limoeiro").get();

    return snapshot.docs.map(
      (doc) => ({ id: doc.id, ...doc.data() } as Contact)
    );
  } catch (error) {
    console.error("Erro ao buscar contatos:", error);
    return [];
  }
};

const removeContactFirestore = async (id: string) => {
  try {
    // Remove pontos e traço do CPF
    //const sanitizedCpf = cpf.replace(/[.-]/g, '');

    await firestore.collection("contacts").doc(id).delete();
    console.log("Contato removido do Firestore com sucesso!");
  } catch (error) {
    console.error("Erro ao remover contato do Firestore:", error);
    throw error;
  }
};

const updateContactFirestore = async (contact: Contact) => {
  try {
    const { cpf, id, ...contactData } = contact;
    const sanitizedCpf = cpf.replace(/[.-]/g, "");

    // Cria uma referência ao documento no Firestore usando o ID do contato
    const contactRef = firestore.collection("limoeiro").doc(id);

    // Atualiza os dados do contato no documento
    await contactRef.update({
      cpf: sanitizedCpf,
      ...contactData,
    });

    console.log("Contato atualizado no Firestore com sucesso!");
  } catch (error) {
    console.error("Erro ao atualizar contato no Firestore:", error);
    throw error;
  }
};

const addStudentFirestore = async (student: StudentData) => {
  try {
    const { cpf, id, ...studentData } = student;

    // Remove pontos do CPF
    const sanitizedCpf = cpf.replace(/[.-]/g, "");

    // Cria um novo documento usando o ID fornecido
    const studentRef = firestore
      .collection('matriculas2025')
      .doc(id);

    // Adiciona os dados do contato ao documento
    await studentRef.set({
      cpf: sanitizedCpf,
      id,
      ...studentData,
    });

    //alert("Matrícula efetuada com sucesso!");
  } catch (error) {
    console.error("Erro ao efetuar matrícula:", error);
    alert("Erro ao efetuar matrícula");
    throw error;
  }
};

const addCoursesFirestore = async (course: TurmaOptionData) => {
  try {
    const { id, ...courseData } = course;

    // Cria um novo documento usando o ID fornecido
    const courseRef = firestore.collection('courses').doc(id);

    // Adiciona os dados do curso ao documento
    await courseRef.set({
      id,
      ...courseData,
    });

    console.log("Curso gravado com sucesso!");
  } catch (error) {
    console.error("Erro ao gravar novo curso:", error);
    throw error;
  }
};

const addSchedulesFirestore = async (course: ScheduleData) => {
  try {
    const { id, ...scheduleData } = course;

    // Cria um novo documento usando o ID fornecido
    const courseRef = firestore.collection('atendimentos').doc(id);
    const scheduleRef_backup = firestore.collection('atendimentosBackup').doc(id);

    // Adiciona os dados do curso ao documento
    await courseRef.set({
      id,
      ...scheduleData,
    });

    // Adiciona os dados do atendimento backup
    await scheduleRef_backup.set({
      id,
      ...scheduleData,
    });

    console.log("Atendimento gravado com sucesso!");
  } catch (error) {
    console.error("Erro ao gravar novo atendimento:", error);
    throw error;
  }
};

const getCoursesFirestore = async (): Promise<TurmaOptionData[]> => {
  try {
    const snapshot = await firestore.collection('courses').get();
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as TurmaOptionData));
  } catch (error) {
    console.error('Erro ao buscar cursos:', error);
    return [];
  }
};

const removeSchedulesFirestore = async (scheduleId: string) => {
  try {
    // Crie uma referência ao documento no Firestore usando o ID do estudante
    const courseRef = firestore.collection("atendimentos").doc(scheduleId);

    // Remova o documento do Firestore
    await courseRef.delete();

    console.log('agendamento deletado com sucesso')
  } catch (error) {
    console.error("Erro ao remover curso no Firestore:", error);
    throw error;
  }
};

const removeSchedulesBackupFirestore = async (scheduleId: string) => {
  try {
    // Crie uma referência ao documento no Firestore usando o ID do estudante
    const scheduleRef_backup = firestore.collection('atendimentosBackup').doc(scheduleId);

    // Remova o documento do Firestore
    await scheduleRef_backup.delete();
    console.log('agendamento backup deletado com sucesso')
  } catch (error) {
    console.error("Erro ao remover curso no Firestore:", error);
    throw error;
  }
};


const getSchedulesFirestore = async (): Promise<ScheduleData[]> => {
  try {
    const snapshot = await firestore.collection('atendimentos').get();
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as ScheduleData));
  } catch (error) {
    console.error('Erro ao buscar atendimentos:', error);
    return [];
  }
};

const getSchedulesBackupFirestore = async (): Promise<ScheduleData[]> => {
  try {
    const snapshot = await firestore.collection('atendimentosBackup').get();
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as ScheduleData));
  } catch (error) {
    console.error('Erro ao buscar atendimentos:', error);
    return [];
  }
};
const removeCoursesFirestore = async (courseId: string) => {
  try {
    // Crie uma referência ao documento no Firestore usando o ID do estudante
    const courseRef = firestore.collection("courses").doc(courseId);

    // Remova o documento do Firestore
    await courseRef.delete();
  } catch (error) {
    console.error("Erro ao remover curso no Firestore:", error);
    throw error;
  }
};

const getStudentsFirestore = async (): Promise<StudentData[]> => {
  try {
    const snapshot = await firestore.collection('matriculas2025').get();
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as StudentData));
  } catch (error) {
    console.error('Erro ao buscar contatos:', error);
    return [];
  }
};

const updateStudentFirestore = async (student: StudentData) => {
  try {
    const { cpf, id, ...studentData } = student;
    const sanitizedCpf = cpf.replace(/[.-]/g, "");

    // Cria uma referência ao documento no Firestore usando o ID do contato
    const contactRef = firestore.collection('matriculas2025').doc(id);

    // Atualiza os dados do contato no documento
    await contactRef.update({
      cpf: sanitizedCpf,
      ...studentData,
    });

    console.log("Contato atualizado no Firestore com sucesso!");
  } catch (error) {
    console.error("Erro ao atualizar contato no Firestore:", error);
    throw error;
  }
};

const getStudentUserFirestoreByUidOrEmail = async (searchBy: 'uid' | 'email', value: string): Promise<UserData> => {
  try {
    const collectionRef = collection(firestore, 'users');

    let q: any; // Define a variável de consulta
    if (searchBy === 'uid') {
      q = query(collectionRef, where('uid', '==', value));
    } else if (searchBy === 'email') {
      q = query(collectionRef, where('email', '==', value));
    } else {
      throw new Error('Parâmetro de busca inválido');
    }

    const snapshot = await getDocs(q);

    return snapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data() } as UserData))[0];
  } catch (error) {
    console.error('Erro ao buscar estudantes:', error);
    return {uid: '', id: '', isFirstAccess: true, email: '', role: 'student'};
  }
};

// Função para obter dados do estudante por UID ou e-mail
const getStudentFullDataFirestoreByIdOrEmail = async (searchBy: 'id' | 'email', value: string): Promise<StudentData | any> => {
    try {
      const collectionRef = collection(firestore, 'matriculas2025');

      let q: any; // Define a variável de consulta
      if (searchBy === 'id') {
        q = query(collectionRef, where('id', '==', value));
      } else if (searchBy === 'email') {
        q = query(collectionRef, where('email', '==', value));
      } else {
        throw new Error('Parâmetro de busca inválido');
      }
  
      const snapshot = await getDocs(q);
  
      return snapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data() } as StudentData))[0];
    } catch (error) {
      console.error('Erro ao buscar estudantes:', error);
      throw error;
    }
  };

// Função para obter dados de pagamento do estudante por cpf ou e-mail
const getStudentFirestoreByCpfOrEmail = async (searchBy: 'cpf' | 'email', value: string): Promise<StudentData | any> => {
  try {
    const collectionRef = collection(firestore, 'matriculas2025');

    let q: any; // Define a variável de consulta
    if (searchBy === 'cpf') {
      q = query(collectionRef, where('cpf', '==', value));
    } else if (searchBy === 'email') {
      q = query(collectionRef, where('email', '==', value));
    } else {
      throw new Error('Parâmetro de busca inválido');
    }

    const snapshot = await getDocs(q);

    return snapshot.docs.map((doc: any) => ({ id: doc.id, ...doc.data() } as StudentData))[0];
  } catch (error) {
    console.error('Erro ao buscar estudantes:', error);
    throw error;
  }
};

//função de atualizar pagamentos pelo ADM
const updateStudentPaymentFirestore = async (studentId: string, month: string, isChecked: boolean, numberMonth: number, numberMatricula: number) => {
  try {
    // Cria uma referência ao documento no Firestore usando o ID do estudante
    const studentRef = firestore.collection('matriculas2025').doc(studentId);
    
    if (numberMonth === numberMatricula) {
      // Atualiza o valor correspondente ao mês no documento
      await studentRef.update({
        [`${month}Pay`]: isChecked,
        pagamentoMatricula: isChecked,
      });

    } else {
      // Atualiza o valor correspondente ao mês no documento
      await studentRef.update({
        [`${month}Pay`]: isChecked,
      });
    }

    // console.log(`Pagamento de ${month} atualizado no Firestore com sucesso!`);
  } catch (error) {
    // console.error(`Erro ao atualizar pagamento de ${month} no Firestore:`, error);
    throw error;
  }
};

const removeStudentFirestore = async (studentId: string) => {
  try {
    // Crie uma referência ao documento no Firestore usando o ID do estudante
    const studentRef = firestore.collection("matriculas2025").doc(studentId);

    // Remova o documento do Firestore
    await studentRef.delete();
  } catch (error) {
    console.error("Erro ao remover aluno no Firestore:", error);
    throw error;
  }
};

const updateFullStudentDataFirestore = async (updatedData: StudentData) => {
  try {
    // Crie uma referência ao documento no Firestore usando o ID do estudante
    const studentRef = firestore
      .collection("matriculas2025")
      .doc(updatedData.id);

    // Atualize o documento no Firestore com os novos dados
    await studentRef.update(updatedData);
  } catch (error) {
    console.error("Erro ao atualizar aluno no Firestore:", error);
    throw error;
  }
};

const updateCourseFirestore = async (course: TurmaOptionData) => {
  try {
    const { id, ...courseData } = course;
    const courseRef = firestore.collection('courses').doc(id);
    await courseRef.update(courseData);
    console.log('Curso atualizado com sucesso!');
  } catch (error) {
    console.error('Erro ao atualizar curso no Firestore:', error);
    throw error;
  }
};

/**
 * Updates a schedule in the Firestore database.
 *
 * @param {ScheduleData} schedule - The schedule data to be updated.
 * @return {Promise<void>} - A promise that resolves when the update is successful.
 * @throws {Error} - If there is an error updating the schedule in Firestore.
 */
const updateSchedulesFirestore = async (schedule: ScheduleData) => {
  try {
    const { id, alunos, ...scheduleData } = schedule;
    const courseRef = firestore.collection('atendimentos').doc(id);

    await courseRef.update(scheduleData);
    console.log('Atendimentos atualizado com sucesso!');
  } catch (error) {
    console.error('Erro ao atualizar atendimento no Firestore:', error);
    throw error;
  }
};
/*
const updateSchedulesStudentsFirestore = async (id: string, updateData: Partial<ScheduleData>) => {
  try {
    const courseRef = firestore.collection('atendimentos').doc(id);
    await courseRef.update(updateData);
    console.log('Atendimentos atualizado com sucesso!');
  } catch (error) {
    console.error('Erro ao atualizar atendimento no Firestore:', error);
    throw error;
  }
};
*/

/**
 * Updates the students and the number of available seats in a schedule in the Firestore 'atendimentos' collection.
 *
 * @param {string} id - The ID of the schedule to update.
 * @param {Aluno} aluno - The student to add to the schedule.
 * @param {number} qtdVagas - The updated number of available seats in the schedule.
 * @return {Promise<void>} A promise that resolves when the update is successful.
 * @throws {Error} If there is an error updating the schedule in the Firestore.
 */
const updateSchedulesStudentsFirestore = async (id: string, aluno: Aluno, qtdVagas: number) => {
  try {
    const courseRef = firestore.collection('atendimentos').doc(id);
    await courseRef.update({
      alunos: firebase.firestore.FieldValue.arrayUnion(aluno),
      qtdVagas: qtdVagas
    });
    console.log('Atendimentos atualizado com sucesso!');
  } catch (error) {
    console.error('Erro ao atualizar atendimento no Firestore:', error);
    throw error;
  }
};

/**
 * Updates a schedule in the Firestore 'atendimentosBackup' collection.
 *
 * @param {ScheduleData} schedule - The schedule data to be updated.
 * @return {Promise<void>} - A promise that resolves when the update is successful.
 * @throws {Error} - If there is an error updating the schedule in Firestore.
 */
const updateSchedulesBackupFirestore = async (schedule: ScheduleData) => {
  try {
    const { id, ...scheduleData } = schedule;

    const scheduleRef_backup = firestore.collection('atendimentosBackup').doc(id);

    await scheduleRef_backup.update(scheduleData);
    console.log('Atendimentos Backup atualizado com sucesso!');
  } catch (error) {
    console.error('Erro ao atualizar atendimento no Firestore:', error);
    throw error;
  }
};

const updatePackageFirestore = async (schedule: PackageData) => {
  try {
    const { id, ...scheduleData } = schedule;
    const courseRef = firestore.collection('pacotesDeRedacao').doc(id);
    await courseRef.update(scheduleData);
    console.log('Pacote atualizado com sucesso!');
  } catch (error) {
    console.error('Erro ao atualizar pacotes de Redação no Firestore:', error);
    throw error;
  }
};

const removePackageFirestore = async (scheduleId: string) => {
  try {
    // Crie uma referência ao documento no Firestore usando o ID do estudante
    const courseRef = firestore.collection("pacotesDeRedacao").doc(scheduleId);

    // Remova o documento do Firestore
    await courseRef.delete();
  } catch (error) {
    console.error("Erro ao remover pacotes de Redação no Firestore:", error);
    throw error;
  }
};

const getPackagesFirestore = async (): Promise<PackageData[]> => {
  try {
    const snapshot = await firestore.collection('pacotesDeRedacao').get();
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as PackageData));
  } catch (error) {
    console.error('Erro ao buscar pacotes de Redação:', error);
    return [];
  }
};

const addPackagesFirestore = async (packages: PackageData) => {
  try {
    const { id, ...packageData } = packages;

    // Cria um novo documento usando o ID fornecido
    const courseRef = firestore.collection('pacotesDeRedacao').doc(id);

    // Adiciona os dados do curso ao documento
    await courseRef.set({
      id,
      ...packageData,
    });

    console.log("Atendimento gravado com sucesso!");
  } catch (error) {
    console.error("Erro ao gravar novo atendimento:", error);
    throw error;
  }
};

const addMaterialsFirestore = async (material: MaterialsData) => {
  try {
    const { id, ...materialData } = material;

    // Cria um novo documento usando o ID fornecido
    const materialRef = firestore.collection('materiaisData').doc(id);

    // Adiciona os dados do curso ao documento
    await materialRef.set({
      id,
      ...materialData,
    });

    console.log("Material gravado com sucesso!");
  } catch (error) {
    console.error("Erro ao gravar novo material:", error);
    throw error;
  }
};

const updateMaterialsFirestore = async (material: MaterialsData) => {
  try {
    const { id, ...materialData } = material;
    const materialRef = firestore.collection('materiaisData').doc(id);
    await materialRef.update(materialData);
    console.log('Material atualizado com sucesso!');
  } catch (error) {
    console.error('Erro ao atualizar material no Firestore:', error);
    throw error;
  }
};

const removeMaterialsFirestore = async (scheduleId: string) => {
  try {
    // Crie uma referência ao documento no Firestore usando o ID do estudante
    const materialRef = firestore.collection("materiaisData").doc(scheduleId);

    // Remova o documento do Firestore
    await materialRef.delete();
  } catch (error) {
    console.error("Erro ao remover material no Firestore:", error);
    throw error;
  }
};

const getMaterialsFirestore = async (): Promise<MaterialsData[]> => {
  try {
    const snapshot = await firestore.collection('materiaisData').get();
    return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() } as MaterialsData));
  } catch (error) {
    console.error('Erro ao buscar materiais:', error);
    return [];
  }
};

const getUsersFirestore = async (): Promise<AdmUserData[]> => {
  try {
    const snapshot = await firestore.collection("users").get();

    return snapshot.docs
      .map((doc) => ({ id: doc.id, ...doc.data() } as AdmUserData))
      .filter((user) => user.role !== 'student');
  } catch (error) {
    console.error("Erro ao buscar usuários:", error);
    return [];
  }
};



export {
  addContactFirestore,
  getContactsFirestore,
  removeContactFirestore,
  updateContactFirestore,
  addStudentFirestore,
  getStudentsFirestore,
  updateStudentFirestore,
  getStudentUserFirestoreByUidOrEmail,
  getStudentFullDataFirestoreByIdOrEmail,
  getStudentFirestoreByCpfOrEmail,
  updateStudentPaymentFirestore,
  removeStudentFirestore,
  updateFullStudentDataFirestore,
  addCoursesFirestore,
  getCoursesFirestore,
  removeCoursesFirestore,
  updateCourseFirestore,
  getUsersFirestore,
  addSchedulesFirestore,
  removeSchedulesFirestore,
  getSchedulesFirestore,
  updateSchedulesFirestore,
  updateSchedulesStudentsFirestore,
  removePackageFirestore,
  getPackagesFirestore,
  updatePackageFirestore,
  addPackagesFirestore,
  getMaterialsFirestore,
  removeMaterialsFirestore,
  updateMaterialsFirestore,
  addMaterialsFirestore,
  updateSchedulesBackupFirestore,
  getSchedulesBackupFirestore,
  removeSchedulesBackupFirestore,
};
